import React from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { Fade } from 'react-awesome-reveal';
import icon1 from '../../assets/images/icon1.png';
import icon2 from '../../assets/images/icon2.png';
import icon3 from '../../assets/images/icon3.png';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function Hero({ data }) {
  console.log(data);
  const { t } = useTranslation('global');

  // Check if data and data.products[0] are available before rendering the component
  if (!data || !data.products || !data.products[0]) {
    return null;
  }

  return (
    <section className='min-h-[100vh] overflow-x-hidden flex items-center hero bg-cover'>
      <div className="container">
        <div className="flex flex-wrap items-center">
          <div className='w-full py-2 md:py-0 relative md:w-1/2 lg:w-1/3'>
            <Fade direction='up' triggerOnce={true} className='absolute top-0 right-0'>
              <img src={icon2} className='first' alt="icon" />
            </Fade>
            <Fade direction='up' triggerOnce={true} className='absolute top-0 left-0' delay={400}>
              <img src={icon3} className='second' alt="icon" />
            </Fade>
            <Fade direction='up' triggerOnce={true} className='absolute bottom-0 right-0' delay={800}>
              <img src={icon1} className='third' alt="icon" />
            </Fade>
            <Fade direction='up' triggerOnce={true} delay={1200}>
              <Link to={`/products/car-details/${data.products[0].id}`}>
                <div className='rotate group bg-gradient-to-b from-[#F90606E5] to-[#CE5D0CE5] font-Poppins w-1/2 flex mx-auto flex-col rounded-xl p-4'>
                  <div>
                    <img src={data.products[0].mainImage} className='w-full' alt="car" />
                  </div>
                  <div className='prose mt-4 group-hover:-translate-x-16 group-hover:scale-110 duration-[0.5s] group-hover:-translate-y-9'>
                    <p className='text-white text-[15px] mb-3'>{data.products[0].categoryName}</p>
                    <h3 className='font-semibold text-white'>{data.products[0].name}</h3>
                    <div className='bg-[#b5350e9c] shadow-[10px_10px_3px_#CE5D0C80] group-hover:-translate-x-16 group-hover:scale-110 duration-[0.5s] rounded-xl p-2 flex justify-between'>
                      <div className='prose'>
                        <p className='text-[#FDE7FD] mb-2'>{t('custome_text.current_price')}</p>
                        <h5 className='text-white font-semibold'>{data.products[0].price} EGP</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </Fade>
          </div>
          <div className='w-full py-2 md:py-0 relative md:w-1/2 lg:w-1/3'>
            <Fade direction='up' triggerOnce={true} className='absolute top-0 right-0'>
              <img src={icon2} className='first' alt="icon" />
            </Fade>
            <Fade direction='up' triggerOnce={true} className='absolute top-0 left-0' delay={400}>
              <img src={icon3} className='second' alt="icon" />
            </Fade>
            <Fade direction='up' triggerOnce={true} className='absolute bottom-0 right-0' delay={800}>
              <img src={icon1} className='third' alt="icon" />
            </Fade>
            <Fade direction='up' triggerOnce={true} delay={1200}>
              <Link to={`/products/car-details/${data.products[0].id}`}>
                <div className='rotate group bg-gradient-to-b from-[#F90606E5] to-[#CE5D0CE5] font-Poppins w-1/2 flex mx-auto flex-col rounded-xl p-4'>
                  <div>
                    <img src={data.products[0].mainImage} className='w-full' alt="car" />
                  </div>
                  <div className='prose mt-4 group-hover:-translate-x-16 group-hover:scale-110 duration-[0.5s] group-hover:-translate-y-9'>
                    <p className='text-white text-[15px] mb-3'>{data.products[0].categoryName}</p>
                    <h3 className='font-semibold text-white'>{data.products[0].name}</h3>
                    <div className='bg-[#b5350e9c] shadow-[10px_10px_3px_#CE5D0C80] group-hover:-translate-x-16 group-hover:scale-110 duration-[0.5s] rounded-xl p-2 flex justify-between'>
                      <div className='prose'>
                        <p className='text-[#FDE7FD] mb-2'>{t('custome_text.current_price')}</p>
                        <h5 className='text-white font-semibold'>{data.products[0].price} EGP</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </Fade>
          </div>
          <div className='w-full py-2 md:py-0 relative md:w-1/2 lg:w-1/3'>
            <Fade direction='up' triggerOnce={true} className='absolute top-0 right-0'>
              <img src={icon2} className='first' alt="icon" />
            </Fade>
            <Fade direction='up' triggerOnce={true} className='absolute top-0 left-0' delay={400}>
              <img src={icon3} className='second' alt="icon" />
            </Fade>
            <Fade direction='up' triggerOnce={true} className='absolute bottom-0 right-0' delay={800}>
              <img src={icon1} className='third' alt="icon" />
            </Fade>
            <Fade direction='up' triggerOnce={true} delay={1200}>
              <Link to={`/products/car-details/${data.products[0].id}`}>
                <div className='rotate group bg-gradient-to-b from-[#F90606E5] to-[#CE5D0CE5] font-Poppins w-1/2 flex mx-auto flex-col rounded-xl p-4'>
                  <div>
                    <img src={data.products[0].mainImage} className='w-full' alt="car" />
                  </div>
                  <div className='prose mt-4 group-hover:-translate-x-16 group-hover:scale-110 duration-[0.5s] group-hover:-translate-y-9'>
                    <p className='text-white text-[15px] mb-3'>{data.products[0].categoryName}</p>
                    <h3 className='font-semibold text-white'>{data.products[0].name}</h3>
                    <div className='bg-[#b5350e9c] shadow-[10px_10px_3px_#CE5D0C80] group-hover:-translate-x-16 group-hover:scale-110 duration-[0.5s] rounded-xl p-2 flex justify-between'>
                      <div className='prose'>
                        <p className='text-[#FDE7FD] mb-2'>{t('custome_text.current_price')}</p>
                        <h5 className='text-white font-semibold'>{data.products[0].price} EGP</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </Fade>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
