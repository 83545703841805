import React, { useContext, useEffect } from 'react';
import logo from '../../assets/images/Frame 10001.png'
import { CiFacebook, CiInstagram, CiTwitter, CiYoutube } from "react-icons/ci";
import { FaFacebookF, FaInstagram, FaLinkedin, FaSnapchat, FaTiktok, FaTwitter, FaWhatsapp, FaYoutube } from "react-icons/fa";
import { LiaTelegramPlane } from "react-icons/lia";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TranslateContext } from '../../context/TranslateContext';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFooterData } from '../../redux/slices/footerSlice';
import toast from 'react-hot-toast';
function Footer() {

  let { footerData, error } = useSelector(({ footerData }) => footerData);
  const { arLang } = useContext(TranslateContext);
  console.log(footerData['icons ']);
  let dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchFooterData())
    if (error) {
      toast.error(error);
    }
  }, [])
  useEffect(() => {
    dispatch(fetchFooterData())
  }, [arLang])
  const { t } = useTranslation("global");
  const links = [
    { link: t("navlinks.about_us"), path: '/about-us' },
    { link: t("navlinks.services"), path: '/services' },
    { link: t("navlinks.products"), path: '/products' },
    { link: t("navlinks.contact_us"), path: '/contact-us' },
  ]
  return <>
    <footer className='py-14 font-Poppins'>
      <div className="container">
        <div className='flex flex-wrap border-b border-[#F3923D] pb-6 items-center'>
          <div className='w-full lg:w-1/2 my-2'>
            <div>
              <img src={footerData?.footer?.logo} className='mb-3' alt="Al-Baqari Motors" />
              <p>{footerData?.footer?.overview}</p>
              <p className='my-4'>{t('custome_text.tax')}: <span dir='ltr'>220-419-949</span></p>
            </div>
          </div>
          <div className='w-full lg:w-1/2 my-2'>
            <div className='flex flex-wrap'>
              <div className='w-full order-2 md:order-1 my-1 md:w-1/2 px-1'>
                <h5 className='font-semibold mx-2 text-[#F0F6FF]'>{t('custome_text.company')}</h5>
                <ul className='list-none m-0 p-0'>
                  {links.map((link, i) =>
                    <li key={i} className='mx-2 my-2'>
                      <Link className='link' to={link.path}>{link.link}</Link>
                    </li>
                  )}
                </ul>
              </div>
              <div className='w-full order-1 md:order-2 my-1 md:w-1/2 px-1'>
                <h5 className='text-white pb-4'>{t('custome_text.get_updated')}</h5>
                <div className='relative'>
                  <label className="input bg-[#d7d6dc13] input-bordered flex items-center gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 16 16"
                      fill="currentColor"
                      className="h-4 w-4 opacity-70">
                      <path
                        d="M2.5 3A1.5 1.5 0 0 0 1 4.5v.793c.026.009.051.02.076.032L7.674 8.51c.206.1.446.1.652 0l6.598-3.185A.755.755 0 0 1 15 5.293V4.5A1.5 1.5 0 0 0 13.5 3h-11Z" />
                      <path
                        d="M15 6.954 8.978 9.86a2.25 2.25 0 0 1-1.956 0L1 6.954V11.5A1.5 1.5 0 0 0 2.5 13h11a1.5 1.5 0 0 0 1.5-1.5V6.954Z" />
                    </svg>
                    <input type="text" className="grow bg-[#d7d6dc13]" placeholder={t('placeholders.email')} />
                  </label>
                  <button className={`bg-[#CDE2FE] absolute top-1/2 -translate-y-1/2 ${arLang ? 'left-0' : 'right-0'} mx-2 py-2 px-3 text-[#11315B] rounded-lg`}>
                    {t('placeholders.subscribe')}
                  </button>
                </div>
                <div className='pt-4'>
                  <h6 className='font-semibold text-white'>{t('custome_text.join_comunity')}</h6>
                  <ul className='list-none mt-3 flex items-center'>
                    {footerData?.['icons ']?.map((icon) => <li className='me-3'>
                      <a href={icon.link} target='_blank'>
                        {icon.classname == 'FaFacebook' ? <FaFacebookF fontSize={'30px'} color='#fff' />
                          : icon.classname == 'FaInstagram' ? <FaInstagram fontSize={'30px'} color='#fff' />
                            : icon.classname == 'FaWhatsapp' ? <FaWhatsapp fontSize={'30px'} color='#fff' />
                              : icon.classname == 'FaTwitter' ? <FaTwitter fontSize={'30px'} color='#fff' />
                                : icon.classname == 'FaLinkedin' ? <FaLinkedin fontSize={'30px'} color='#fff' />
                                  : icon.classname == 'FaTiktok' ? <FaTiktok fontSize={'30px'} color='#fff' />
                                    : icon.classname == 'FaSnapchat' ? <FaSnapchat fontSize={'30px'} color='#fff' />
                                      : <FaYoutube fontSize={'30px'} color='#fff' />
                        }

                      </a>
                    </li>)}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='flex items-center justify-between pt-9'>
          <p className='text-white'>© 2024 Al-Baqari Motors.com. All rights reserved. <a href="https://www.allsafeeg.com/" className='link text-blue-700'>All safe</a></p>
          <p className='text-white'>{t('privacy')}</p>
        </div>
      </div>
    </footer>
  </>
}

export default Footer;