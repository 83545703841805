import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./style.css";
// import './styles.css';

import { Autoplay, Navigation, Pagination } from "swiper/modules";

export default function SwiperElement({
  slidesPerView,
  spaceBetween,
  autoplay,
  className,
  loop,
  modules,
  navigation,
  numberOfItems,
  slideData,
  dots,
  res,
  paginationClass,
  navigationClass,
}) {
  const renderSlides = () => {
    return slideData
      .slice(0, numberOfItems)
      .map((data, index) => <SwiperSlide key={index}>{data}</SwiperSlide>);
  };
  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return `<span class="${className} ${paginationClass}"></span>`;
    },
  };

  return (
    <Swiper
      slidesPerView={slidesPerView}
      spaceBetween={spaceBetween}
      autoplay={
        autoplay && {
          delay: 2500,
          disableOnInteraction: false,
        }
      }
      breakpoints={
        res && {
          400: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          676: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: slidesPerView,
            spaceBetween: 50,
          },
        }
      }
      className={className}
      loop={loop}
      modules={[Pagination, Navigation, Autoplay]}
      navigation={{
        ...navigation,
        nextEl: `.${navigationClass}-next`,
        prevEl: `.${navigationClass}-prev`,
      }}
      pagination={dots && pagination}
      centeredSlides={true}
    >
      {renderSlides()}
      {navigation && (
        <>
          <div
            className={`${navigationClass} md:block hidden ${navigationClass}-next swiper-button-next`}
          ></div>
          <div
            className={`${navigationClass} md:block hidden ${navigationClass}-prev swiper-button-prev`}
          ></div>
        </>
      )}
    </Swiper>
  );
}
