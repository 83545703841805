import { Fade } from "react-awesome-reveal";
import "./style.css";
export default function MainTitle({ outline, normal, className, direction }) {
  return (
    <Fade direction={direction}>
      <div className="mainTitle mb-20">
        <h2 className={`${className}`}>
          <span className={`outline-text font-extrabold text-center lg:text-5xl md:text-4xl text-3xl`}>{outline} </span>
          <span className="line italic font-extrabold lg:text-5xl md:text-4xl text-3xl text-white relative z-[1] after:z-[-1] after:absolute after:-bottom-0 after:w-full after:h-5 after:left-0 after:bg-[#F2881F]">
            {normal}
          </span>
        </h2>
      </div>
    </Fade>
  );
}
