import { Fade, Slide } from "react-awesome-reveal";
import car from "../../assets/images/car.png";
// import MainButton from "../button";
import SwiperElement from "../swiper";
import "./style.css";
import { FaArrowRight } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
export default function HeroSection() {
  const { t } = useTranslation("global");
  const slideItem = () => {
    const num = 3;
    let item;
    let arr = [];
    for (let i = 0; i < num; i++) {
      item = (
        <section className="mt-[7rem]">
          <div className="content lg:absolute relative lg:top-28 lg:left10 text-center lg:text-left mt-4 z-10">
            <Fade direction="down" delay={200} duration={700}>
              <h2 className="text-7xl font-bold text-white">
                BUY LUXURY <br /> CARS
              </h2>
              <p className="lg:w-96 w-72 mx-auto lg:mx-0 my-5 text-[20px] text-white">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Accusantium ab minima fugit reiciendis error nesciunt.
              </p>
              <Link to={'/contact-us'}>
                <button className='border-0 p-2 rounded-xl group outline-0 bg-gradient-to-r from-[#F90606] to-[#CE5D0C] text-white'>
                  <div className='flex items-center justify-between w-full'>
                    <span className='font-Poppins'>{t('navlinks.contact_us')}</span>
                    <span className='bg-white text-black duration-[0.2s] ms-4 p-2 rounded-md group-hover:text-white group-hover:bg-black'><FaArrowRight /></span>
                  </div>
                </button>
              </Link>
            </Fade>
          </div>
          <Slide direction="up" duration={700} delay={200}>
            <img className="m-auto relative z-10" src={car} alt="" />
          </Slide>
        </section>
      );
      arr.push(item);
    }
    return arr;
  };
  return (
    <section dir="ltr" className="hero-section flex items-center justify-center hero min-h-[100vh]">
      <div className="container">
        <SwiperElement
          slideData={slideItem()}
          slidesPerView={1}
          numberOfItems={3}
          autoplay={true}
          loop={true}
          dots={false}
          navigation={true}
          navigationClass={"custom-nav"}
          paginationClass={"custom-pagi"}
        />
      </div>
    </section>
  );
}
