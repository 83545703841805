import React, { useContext, useEffect } from 'react';
import Hero from '../Hero/Hero';
import AboutUsSection from '../AboutUsSection/AboutUsSection';
import ProductSection from '../ProductSection/ProductSection';
import ServicesSection from '../ServicesSection/ServicesSection';
import OurPartnersSection from '../OurPartnersSection/OurPartnersSection';
import FooterBanner from '../FooterBanner/FooterBanner';
import HeroSection from '../hero-section';
import { fetchHomeSlice } from '../../redux/slices/homeSlice';
import { useDispatch, useSelector } from 'react-redux';
import toast, { Toaster } from 'react-hot-toast';
import Loading from '../Loading/Loading';
import { TranslateContext } from '../../context/TranslateContext';

function Home() {
  let { homeData, isLoading, error } = useSelector(({ homeData }) => homeData);
  let dispatch = useDispatch()
  let lang = localStorage.getItem('lang')
  const { arLang } = useContext(TranslateContext);
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchHomeSlice())
    if (error) {
      toast.error(error);
    }
  }, [])
  useEffect(() => {
    dispatch(fetchHomeSlice())
  }, [arLang])
  return <>
    {isLoading ? <Loading /> :
      <>
        <Toaster />
        <HeroSection data={homeData?.heroSection} />
        <AboutUsSection data={homeData?.aboutSection} />
        <ProductSection data={homeData?.products} />
        <ServicesSection data={homeData?.services} />
        <OurPartnersSection data={homeData?.partener} />
        <FooterBanner />
      </>
    }
  </>
}

export default Home;