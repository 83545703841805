import React, { useContext, useEffect, useState } from 'react';
import { Fade } from 'react-awesome-reveal';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { fetchProductDetalisData } from '../../redux/slices/productDetailsSlice';
import FooterBanner from '../FooterBanner/FooterBanner';
import Loading from '../Loading/Loading';
import ProductCard from '../ProductCard/ProductCard';
import { TranslateContext } from '../../context/TranslateContext';
function CarDetails() {
  const { t } = useTranslation('global')
  let { productDetailsData, isLoading, error } = useSelector(({ productDetails }) => productDetails);
  const [mainImg, setMainImg] = useState(undefined);
  const { arLang } = useContext(TranslateContext);
  const [priceYear, setPriceYear] = useState({})
  let dispatch = useDispatch();
  let { id } = useParams()
  useEffect(() => {
    window.scrollTo(0, 0);

    if (error) {
      toast.error(error);
    }
  }, [id]);
  useEffect(() => {
    dispatch(fetchProductDetalisData(id))
    if (error) {
      toast.error(error);
    }
  }, [arLang])
  const [selectedBank, setSelectedBank] = useState();
  const [paymentAdvance, setPaymentAdvance] = useState(0)
  const renderYears = () => {
    let item
    let arr = []
    for (let i = 0; i <= selectedBank?.installmentYears; i++) {
      item = <span key={i}>{i} years</span>
      arr.push(item)
    }
    return arr
  }
  const renderAdvance = () => {
    let item
    let arr = []
    for (let i = +selectedBank?.Deposit; i <= 90; i += 10) {
      item = <span key={i}>{i} %</span>
      arr.push(item)
    }
    console.log('arr: ', arr);

    return arr
  }

  const priceMangement = (total, benefit, installmentYear, advancePayment) => {
    const advance = total * (advancePayment / 100)
    const totalPrice = +total - advance
    const benefits = +benefit / 100
    const benefitsPrice = +totalPrice * +benefits
    const installmentYears = +installmentYear
    const totalAfterBenefits = +totalPrice + +benefitsPrice
    const installmentPrice = +totalAfterBenefits / (+installmentYear * 12)
    let obj = { installmentPrice, totalAfterBenefits, installmentYears }
    setPriceYear(obj)
  }
  const attrs = () => {
    productDetailsData?.attributes?.customAttribute?.map((single) => {
      console.log(single);
    })
  }
  useEffect(() => {
    if (productDetailsData?.product?.image?.length) {
      setMainImg(productDetailsData.product.image[0]);
    }
    if (productDetailsData?.banks?.length) {
      // setSelectedBank(productDetailsData.banks[0])
      priceMangement(0, 0, 1, 0)
    }
  }, [productDetailsData]);
  return <>
    {isLoading ? <Loading /> :
      <>
        <section className='pt-44 font-Poppins'>
          <div className="container">
            <div className="flex flex-wrap">
              <div className='w-full px-4 my-4 lg:w-1/2'>
                <div className='bg-white p-4 rounded-2xl'>
                  <Fade >
                    <img src={mainImg} alt="Car" className='w-full md:h-[500px]' />
                  </Fade>
                </div>
                <Swiper
                  slidesPerView={2}
                  spaceBetween={1}
                  breakpoints={{
                    586: {
                      slidesPerView: 4,
                      spaceBetween: 1,
                    },
                    1024: {
                      slidesPerView: 3,
                      spaceBetween: 1,
                    },
                    1279: {
                      slidesPerView: 4,
                      spaceBetween: 1,
                    },
                  }}
                  className="mySwiper"
                >
                  {productDetailsData?.product?.image?.map((image, i) => (

                    <SwiperSlide key={i}>
                      <div onClick={() => setMainImg(image)} className={`mt-5 cursor-pointer ${image === mainImg ? 'opacity-100 ' : 'opacity-50'}`}>
                        <img src={image} className='w-full h-[150px]' />
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
                <div className="overflow-hidden mt-7 bg-[#313131] rounded-2xl p-5 mb-5">
                  <h3 className='text-white font-semibold text-[20px] py-3'>{t('car_details')}</h3>
                  <div className='flex my-2 items-center justify-between'>
                    <p className='text-white'>Colors:</p>
                    <p className='text-white'>{productDetailsData?.attributes?.colors?.map((color) => <span className={`inline-block mx-2 rounded-full w-[20px] h-[20px]`} style={{
                      backgroundColor: color.color
                    }}></span>)}</p>
                  </div>
                  <hr />
                  {productDetailsData?.attributes?.customAttribute?.map((attr) =>
                    <>
                      <div className='flex my-2 items-center justify-between'>
                        <p className='text-white'>{attr.key}:</p>
                        <p className='text-white'>{attr.value}</p>
                      </div>
                      <hr />
                    </>
                  )}
                </div>
              </div>
              <div className='w-full lg:sticky top-0 px-4 my-4 lg:w-1/2'>
                <div>
                  <div className='pb-6 border-b'>
                    <p className='text-[#BDCADB]'>{productDetailsData?.product?.categoryName}</p>
                    <h3 className='text-white font-semibold text-[20px] md:text-[32px]'>{productDetailsData?.product?.name}</h3>
                  </div>
                  <p className='mt-5'>{productDetailsData?.product?.description}</p>
                  <div className='mt-5 overflow-hidden bg-[#313131] flex flex-wrap items-center rounded-2xl p-5'>
                    <div className="w-full px-2 my-2">
                      <Fade direction='down' delay={400}>
                        <div className='px-6 py-4 text-center bg-white rounded-2xl'>
                          <p className='text-[14px] sm:text-base'>{t('custome_text.current_price')}</p>
                          <p className='text-[#11315B] lg:text-3xl text-[14px] font-semibold'>{productDetailsData?.product?.price} EGP</p>
                        </div>
                      </Fade>
                    </div>
                    <div className='w-full px-2 my-2'>
                      <Link to={`/buy-car/${productDetailsData?.product?.id}/${productDetailsData?.product?.name}`}>
                        <button className='border-0 w-full py-5 px-1 rounded-xl group outline-0 bg-gradient-to-r from-[#F90606] to-[#CE5D0C] text-white'>
                          <span className='font-Poppins font-semibold text-[18px]'>{t('custome_text.buy_now')}</span>
                        </button>
                      </Link>
                    </div>
                  </div>
                  {productDetailsData?.banks?.length == 0 ? '' :
                    <div className='mt-5 overflow-hidden bg-[#313131] rounded-2xl p-5'>
                      <Swiper
                        slidesPerView={2}
                        spaceBetween={2}
                        breakpoints={{
                          586: {
                            slidesPerView: 4,
                            spaceBetween: 2,
                          },
                          1024: {
                            slidesPerView: 3,
                            spaceBetween: 2,
                          },
                          1279: {
                            slidesPerView: 4,
                            spaceBetween: 2,
                          },
                        }}
                        className="mySwiper"
                      >
                        {productDetailsData?.banks?.map((bank, i) => (
                          <SwiperSlide key={i}>
                            <div
                              className={`w--full px-2`}
                              onClick={() => { setSelectedBank(bank); }}
                            >
                              <div className={`border-4 cursor-pointer ${selectedBank?.bankName === bank.bankName ? 'border-orange-700' : ''}`}>
                                <img src={bank.bankImage} className='w-[200px] h-[100px]' alt={bank.bankName} />
                              </div>
                            </div>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                      <div className='my-8'>
                        <p className='text-white'>{t('Advance_payment')}:</p>
                        <input
                          dir='ltr' type="range"
                          min={20}
                          max={90}
                          defaultValue="0" step="10"
                          onChange={(e) => {
                            setPaymentAdvance(e.target.value);
                            priceMangement(productDetailsData?.product?.price, selectedBank?.installmentBenefit, 1, paymentAdvance);
                          }}
                          placeholder="Type here"
                          className="range range-xs [--range-shdw:#E67E23] my-3"
                        />
                        <div dir='ltr' className="flex w-full justify-between px-2 text-xs">
                          {renderAdvance()}
                        </div>
                        <input dir='ltr' type="range" onChange={(e) => priceMangement(productDetailsData?.product?.price, selectedBank?.installmentBenefit, e.target.value, paymentAdvance)} min={0} max={selectedBank?.installmentYears} defaultValue="0" className="range range-xs [--range-shdw:#E67E23]" step="1" />
                        <div dir='ltr' className="flex w-full justify-between px-2 text-xs">
                          {renderYears()}
                        </div>
                      </div>
                      <div className='info mt-5'>
                        {<div className='border-b my-2 border-white flex flex-wrap'>
                          <div className='w-1/3'>
                            <h4 className='text-white my-1 font-semibold'>Final Price :</h4>
                            <h4 className='text-white my-1 font-semibold'>{priceYear.totalAfterBenefits}</h4>
                            {/* <h4 className='text-white my-1'>{selectedBank.final_price_8_months}</h4> */}
                          </div>
                          <div className='w-1/3'>
                            <h4 className='text-white my-1 font-semibold'>{t('price_month')}:</h4>
                            <h4 className='text-white my-1'>{Math.floor(priceYear.installmentPrice) == 'Infinity' ? 0 : Math.floor(priceYear.installmentPrice)}</h4>
                          </div>
                          <div className='w-1/3'>
                            <h4 className='text-white my-1'>{t('benefit')}:</h4>
                            <h4 className='text-white my-1'>{selectedBank?.installmentBenefit}%</h4>
                          </div>
                        </div>}
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='pt-44'>
          <div className="container">
            <video src={productDetailsData?.product?.video} controls autoPlay className='w-full rounded-md'></video>
          </div>
        </section>
        <section dir='ltr' className='py-20'>
          <div className="container">
            <div className='flex items-center justify-between my-3'>
              <div>
                <h3 className='font-Poppins font-semibold md:text-[32px] text-[11px] sm:text-[18px]'>{t('custome_text.more_from_collection')}</h3>
              </div>
              <div className='flex items-center'>
                <Link to={'/products'}>
                  <button className='bg-transparent mx-2 px-5 font-semibold   py-2 border border-[#E67E23] text-[#E67E23]  rounded-lg'>
                    {t('navlinks.see_all')}
                  </button>
                </Link>
                <div className='flex rounded-lg mx-2 prev cursor-pointer w-[40px] h-[40px] border border-[#E67E23] text-[#E67E23] items-center justify-center'>
                  <FaArrowLeft />
                </div>
                <div className='flex rounded-lg mx-2 next cursor-pointer w-[40px] h-[40px] border border-[#E67E23] text-[#E67E23] items-center justify-center'>
                  <FaArrowRight />
                </div>
              </div>
            </div>
            <Fade>
              <Swiper
                slidesPerView={1}
                spaceBetween={10}
                navigation={{
                  prevEl: '.prev',
                  nextEl: '.next',
                }}
                breakpoints={{
                  586: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                  },
                  1279: {
                    slidesPerView: 4,
                    spaceBetween: 30,
                  },
                }}
                modules={[Navigation]}
                className="mySwiper"
              >
                {productDetailsData?.relatedproduct?.map((car) =>
                  <SwiperSlide key={car.id}>
                    <Link to={`/products/car-details/${car.id}`}>
                      <ProductCard image={car.mainImage} collection={car.categoryName} name={car.name} price={car.price} />
                    </Link>
                  </SwiperSlide>
                )}

              </Swiper>
            </Fade>
          </div>
        </section>
        <FooterBanner />
      </>
    }
  </>
}

export default CarDetails;