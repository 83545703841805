import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { baseURL } from "../../api/api";

export const fetchProductDetalisData = createAsyncThunk(
    'productDetailsSlice/fetchProductDetalisData',
    async (id,{rejectWithValue})=>{
        try {
            let {data} = await axios.get(`${baseURL}/product/Details/${id}`,{
              headers:{
                lang: localStorage.getItem('lang')
              }
            });
            return data?.ProductData
        }catch(error){
            return rejectWithValue(error.response?.data || error.message);
        }
    } 
)
let initialState = { productDetailsData: {}, isLoading: false, error: null };
let productDetailsSlice = createSlice({
    name: 'productDetailsSlice',
    initialState,
    extraReducers: (builder) => {
      builder
        .addCase(fetchProductDetalisData.pending, (state) => {
          state.isLoading = true;
          state.error = null; // Clear any previous error
        })
        .addCase(fetchProductDetalisData.fulfilled, (state, action) => {
          state.productDetailsData = action.payload;
          state.isLoading = false;
        })
        .addCase(fetchProductDetalisData.rejected, (state, action) => {
          state.isLoading = false;
          state.error = action.payload || 'Failed to fetch home data';
        });
    }
  });

  export const productDetailsReducer = productDetailsSlice.reducer