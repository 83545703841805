import React, { useEffect } from 'react';
import mc from '../../assets/images/MC2.png'
import { FaRocket } from 'react-icons/fa';
import { Fade } from 'react-awesome-reveal';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import toast, { Toaster } from 'react-hot-toast';
import { useFormik } from 'formik';
import { postBuyCarData } from '../../redux/slices/buyCarSlice';
import * as Yup from 'yup'
function BuyCar() {
  const { t } = useTranslation('global');
  let { id, name } = useParams()
  let { message, error, isLoading } = useSelector(({ buyCar }) => buyCar);
  let dispatch = useDispatch()

  const validationSchema = Yup.object({
    fname: Yup.string().required(t('errors.required')),
    lname: Yup.string().required(t('errors.required')),
    email: Yup.string().email(t('errors.email')),
    phone: Yup.string().matches(/^\d{11}$/, 'Phone number must be 11 digits').required(t('errors.required')),
    message: Yup.string().required(t('errors.required')),
    confirm: Yup.string().required(t('errors.required')),
    type: Yup.string().required(t('errors.required')),
  });
  let formik = useFormik({
    initialValues: {
      fname: '',
      lname: '',
      email: '',
      phone: '',
      product_id: id,
      confirm: '',
      message: '',
      type: '',
    }, onSubmit: (values) => {
      dispatch(postBuyCarData(values));
    }, validationSchema
  })
  useEffect(() => {
    if (message.request) {
      toast.success(message?.request)
    }
    console.log(message);
  }, [message])
  useEffect(() => {
    if (error) {
      toast.error(error?.message)
    }
  }, [error])
  return <>
    <Toaster />
    <section className='pt-36  pb-16 font-inter'>
      <div className="container">
        <div className="flex bg-[#1c1c1d] md:p-6 rounded-2xl flex-wrap">
          <div className='w-full px-4 my-4 lg:w-1/2'>
            <div>
              <Fade direction='down' delay={200}>
                <h3 className='font-semibold text-white text-base sm:text-[25px] md:text-[32px]'>{name}</h3>
              </Fade>
              <form onSubmit={formik.handleSubmit} className='mt-10'>
                <Fade direction='up' delay={400}>
                  <div className='flex flex-wrap items-center'>
                    <div className='w-full px-2 my-3 lg:w-1/2'>
                      <div>
                        <input
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          name='fname'
                          placeholder={t('placeholders.first_name')}
                          className="input input-bordered outline-none bg-[#171212] w-full"
                        />
                        {formik.touched.fname && formik.errors.fname ? (
                          <p className="text-red-700">{formik.errors.fname}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className='w-full px-2 my-3 lg:w-1/2'>
                      <div>
                        <input
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          name='lname'
                          placeholder={t('placeholders.last_name')}
                          className="input input-bordered outline-none bg-[#171212] w-full"
                        />
                        {formik.touched.lname && formik.errors.lname ? (
                          <p className="text-red-700">{formik.errors.lname}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className='w-full px-2 my-3'>
                      <div>
                        <input
                          type="email"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          name='email'
                          placeholder={t('placeholders.email')}
                          className="input input-bordered outline-none bg-[#171212] w-full"
                        />
                        {formik.touched.email && formik.errors.email ? (
                          <p className="text-red-700">{formik.errors.email}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className='w-full px-2 my-3'>
                      <div>
                        <input
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          name='phone'
                          placeholder={t('placeholders.phonenumber')}
                          className="input input-bordered outline-none bg-[#171212] w-full"
                        />
                        {formik.touched.phone && formik.errors.phone ? (
                          <p className="text-red-700">{formik.errors.phone}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className='w-full px-2 my-3'>
                      <div>
                        <input
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          name='message'
                          placeholder={t('placeholders.address')}
                          className="input input-bordered outline-none bg-[#171212] w-full"
                        />
                        {formik.touched.message && formik.errors.message ? (
                          <p className="text-red-700">{formik.errors.message}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className='w-full px-2 my-3'>
                      <div>
                        <select
                          name='confirm'
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className="bg-[#171212] select select-bordered w-full"
                        >
                          <option value={''} disabled selected>{t('placeholders.complete')}</option>
                          <option value={'yes'}>{t('placeholders.yes')}</option>
                          <option value={'no'}>{t('placeholders.no')}</option>
                        </select>
                        {formik.touched.confirm && formik.errors.confirm ? (
                          <p className="text-red-700">{formik.errors.confirm}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className='w-full px-2 my-3'>
                      <div>
                        <select
                          name='type'
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder={t('placeholders.payment_method')}
                          className="bg-[#171212] select select-bordered w-full"
                        >
                          <option disabled value={''} selected>{t('placeholders.payment_method')}</option>
                          <option value={'cash'}>{t('placeholders.cash')}</option>
                          <option value={'installment'}>{t('placeholders.installment')}</option>
                        </select>
                        {formik.touched.type && formik.errors.type ? (
                          <p className="text-red-700">{formik.errors.type}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className='w-full px-2 mt-7'>
                      <button
                        type='submit'
                        className='border-0 px-1 mt-7 w-full rounded-lg group py-2 outline-0 bg-gradient-to-r from-[#F90606] to-[#CE5D0C] text-white'
                      >
                        <div className='flex items-center justify-center'>
                          <span className='font-Poppins font-semibold text-[18px]'>{t('placeholders.submit')}</span>
                          <span className='text-white p-2'><FaRocket /></span>
                        </div>
                      </button>
                    </div>
                  </div>
                </Fade>
              </form>
            </div>
          </div>
          <div className='w-full px-4 my-4 lg:w-1/2'>
            <div>
              <Fade>
                <img src={mc} alt="car" className='w-full' />
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
}

export default BuyCar;