import React, { useContext, useEffect } from 'react';
import img from '../../assets/images/idk.png'
import { Fade } from 'react-awesome-reveal';
import FooterBanner from '../FooterBanner/FooterBanner';
import { useDispatch, useSelector } from 'react-redux';
import { fetchServicesDetalisData } from '../../redux/slices/servicesDetailsSlice';
import toast, { Toaster } from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import Loading from '../Loading/Loading';
import { TranslateContext } from '../../context/TranslateContext';
function ServiceDetails() {
  let { serviseDetailsData, isLoading, error } = useSelector(({ servicesDetails }) => servicesDetails);
  let dispatch = useDispatch();
  const { arLang } = useContext(TranslateContext);
  let { id } = useParams()
  const lang = localStorage.getItem('lang')
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchServicesDetalisData(id))
    if (error) {
      toast.error(error);
    }
  }, [])
  useEffect(() => {
    dispatch(fetchServicesDetalisData(id))
    console.log('helo');
  }, [arLang])
  return <>
    {isLoading ? <Loading /> :
      <>
        <Toaster />
        <section className='pt-40 font-Poppins'>
          <div className="container">
            <h4 className='text-white my-3 font-semibold'>{serviseDetailsData?.product?.name}</h4>
            <p className='text-[#B6BCC7] leading-9 my-3'>
              {serviseDetailsData?.product?.description}
            </p>
            <div className='flex my-11 flex-wrap'>
              <div className='w-full lg:w-1/2'>
                <p className='text-[#B6BCC7] leading-9'>
                  {serviseDetailsData?.product?.description}
                </p>
              </div>
              <div className='w-full overflow-hidden lg:w-1/2'>
                <div className="flex flex-wrap">
                  <div className='w-full px-2 mt-20'>
                    <Fade direction='up' delay={400}>
                      <img src={serviseDetailsData?.product?.image} className='w-full rounded-2xl' alt="img" />
                    </Fade>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <FooterBanner />
      </>
    }
  </>
}

export default ServiceDetails;