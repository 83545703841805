import React, { useContext, useEffect } from 'react';
import image from '../../assets/images/image (1).png'
import { Fade } from 'react-awesome-reveal';
import { Link } from 'react-router-dom';
import ServiceCard from '../ServiceCard/ServiceCard';
import FooterBanner from '../FooterBanner/FooterBanner';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchServicesData } from '../../redux/slices/servicesSlice';
import toast, { Toaster } from 'react-hot-toast';
import Loading from '../Loading/Loading';
import { TranslateContext } from '../../context/TranslateContext';
function Services() {
  let { serviseData, isLoading, error } = useSelector(({ servicesData }) => servicesData);
  const { arLang } = useContext(TranslateContext);
  let dispatch = useDispatch();
  const { t } = useTranslation('global');
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchServicesData())
    if (error) {
      toast.error(error);
    }
  }, [])
  useEffect(() => {
    dispatch(fetchServicesData())
    if (error) {
      toast.error(error);
    }
  }, [arLang])
  return <>
    {isLoading ? <Loading /> :
      <>
        <Toaster />
        <section className='py-16 font-Poppins'>
          <div className="container">
            <div className='py-32'>
              <div className='rounded-2xl  flex items-center h-banner p-8'>
                <div>
                  <img src={image} className='w-full' alt="img" />
                </div>
                <div className='ms-4'>
                  <h3 className='text-[20px] text-white font-semibold'>{t("custome_text.services")}</h3>
                  <p className='text-[#BDCADB]'>{serviseData?.heroSection?.description}</p>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap">
              {serviseData?.services?.map((servise) =>
                <div key={servise.id} className="lg:w-1/3 px-7 my-7 sm:w-1/2 w-full">
                  <Fade>
                    <Link to={`/services/details/${servise.id}`}>
                      <ServiceCard image={servise.image} name={servise.name} overview={servise.overview} />
                    </Link>
                  </Fade>
                </div>
              )}
            </div>
          </div>
        </section>
        <FooterBanner />
      </>
    }
  </>
}

export default Services;