import React from 'react';
import MainTitle from '../MainTitle/MainTitle';
import ServiceCard from '../ServiceCard/ServiceCard';
import { Fade } from 'react-awesome-reveal';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function ServicesSection({ data }) {
  const { t } = useTranslation('global');
  return <>
    <section className='py-16'>
      <MainTitle outline={t('maintitels.services.outline')} normal={t('maintitels.services.span')} className={'text-center'} />
      <div className="container">
        <div className="flex flex-wrap">
          {data?.map((serv) =>
            <div key={serv.id} className="lg:w-1/3 px-7 my-7 sm:w-1/2 w-full">
              <Fade>
                <Link to={`/services/details/${serv.id}`}>
                  <ServiceCard image={serv.image} name={serv.name} overview={serv.overview} />
                </Link>
              </Fade>
            </div>
          )}
        </div>
      </div>
    </section>
  </>
}

export default ServicesSection;