import React, { useContext, useEffect } from 'react';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import { Outlet } from 'react-router-dom';
import { ReactLenis } from 'lenis/react'
import { TranslateContext } from '../../context/TranslateContext';
import { menuContext } from '../../context/SideMenuContext';
import Loading from '../Loading/Loading';
function Layout() {
  const { arLang } = useContext(TranslateContext);
  const { isMenuOpen } = useContext(menuContext);
  useEffect(() => {
    if (isMenuOpen) {
      document.body.classList.add('overflow-hidden')
    } else {
      document.body.classList.remove('overflow-hidden')
    }
  }, [isMenuOpen])
  return <>
    <ReactLenis root options={{ duration: '1.5' }}>
      <div dir={`${arLang ? "rtl" : 'ltr'}`}>
        <Navbar />
        <Outlet />
        <Footer />
      </div>
    </ReactLenis>
  </>
}

export default Layout;