import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { baseURL } from "../../api/api";

export const fetchServicesData = createAsyncThunk(
  'servicesSlice/fetchServicesData',
  async (_, { rejectWithValue }) => {
    try {
      let { data } = await axios.get(`${baseURL}/service`, {
        headers: {
          lang: localStorage.getItem('lang')
        }
      });
      return data?.ProductData
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
)
let initialState = { serviseData: {}, isLoading: false, error: null };
let servicesSlice = createSlice({
  name: 'servicesSlice',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchServicesData.pending, (state) => {
        state.isLoading = true;
        state.error = null; // Clear any previous error
      })
      .addCase(fetchServicesData.fulfilled, (state, action) => {
        state.serviseData = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchServicesData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || 'Failed to fetch home data';
      });
  }
});

export const serviceReducer = servicesSlice.reducer