import React from 'react';
import MainTitle from '../MainTitle/MainTitle';
import ProductCard from '../ProductCard/ProductCard';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { Navigation } from 'swiper/modules';
import { Fade } from 'react-awesome-reveal';
import { useTranslation } from 'react-i18next';
function ProductSection({ data }) {
  const { t } = useTranslation('global');
  return <>
    <section dir='ltr' className='py-20'>
      {/* <MainTitle outline={t('maintitels.products.outline')} normal={t('maintitels.products.span')} className={'text-center'} /> */}
      <div className="container">
        <div className='text-right flex items-center justify-end my-3'>
          <Link to={'/products'}>
            <button className='bg-transparent mx-2 px-5 font-semibold  py-2 border border-[#E67E23] text-[#E67E23]  rounded-lg'>
              {t('navlinks.see_all')}
            </button>
          </Link>
          <div className='flex rounded-lg mx-2 prev cursor-pointer w-[40px] h-[40px] border border-[#E67E23] text-[#E67E23] items-center justify-center'>
            <FaArrowLeft />
          </div>
          <div className='flex rounded-lg mx-2 next cursor-pointer w-[40px] h-[40px] border border-[#E67E23] text-[#E67E23] items-center justify-center'>
            <FaArrowRight />
          </div>
        </div>
        <Fade>
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            navigation={{
              prevEl: '.prev',
              nextEl: '.next',
            }}
            breakpoints={{
              586: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              1279: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
            }}
            modules={[Navigation]}
            className="mySwiper"
          >
            {data?.map((car) =>
              <SwiperSlide key={car.id}>
                <Link to={`/products/car-details/${car.id}`}>
                  <ProductCard data={car} image={car.mainImage} name={car.name} collection={car.categoryName} price={car.price} />
                </Link>
              </SwiperSlide>
            )}
          </Swiper>
        </Fade>
      </div>
    </section>
  </>
}

export default ProductSection;