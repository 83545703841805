import React, { useContext, useEffect, useState } from 'react';
import ProductCard from '../ProductCard/ProductCard';
import { Fade } from 'react-awesome-reveal';
import { Link } from 'react-router-dom';
import FooterBanner from '../FooterBanner/FooterBanner';
import image from '../../assets/images/image (1).png'
import { useTranslation } from 'react-i18next';
import Hero from '../Hero/Hero';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProductsData } from '../../redux/slices/productSlice';
import Loading from '../Loading/Loading';
import { TranslateContext } from '../../context/TranslateContext';
function Products() {
  const { t } = useTranslation('global');
  const { arLang } = useContext(TranslateContext);
  const [bigLoading, setBigLoading] = useState(false);
  let { productsData, isLoading, error } = useSelector(({ productsData }) => productsData);
  const object = {
    search: '',
    category: ''
  }
  let dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchProductsData(object))
  }, [])
  useEffect(() => {
    setBigLoading(true)
    dispatch(fetchProductsData(object))
    console.log('hello');
    setBigLoading(false)
  }, [arLang])
  return <>
    <>
      {bigLoading ? <Loading /> :
        <>
          <Hero data={productsData} />
          <section className='py-16 font-Poppins'>
            <div className="container">
              <div className='py-32'>
                <div className='rounded-2xl  flex items-center h-banner p-8'>
                  <div>
                    <img src={image} className='w-full' alt="img" />
                  </div>
                  <div className='ms-4'>
                    <h3 className='text-[20px] text-white font-semibold'>{t("custome_text.products")}</h3>
                    <p className='text-[#BDCADB]'>{productsData?.heroSection?.description}</p>
                  </div>
                </div>
              </div>
              <div className='my-4'>
                <h2 className='text-[32px] text-white font-semibold'>{t('custome_text.unique')}</h2>
                <p className='text-[#9D9BB9]'>{productsData?.heroSection?.description}</p>
              </div>
              <div className='flex flex-wrap items-center'>
                <div className="w-full px-4 my-4 md:w-2/3">
                  <label className="input text-white bg-[#171212] input-bordered flex items-center gap-2">
                    <input type="text" onChange={(e) => { object.search = e.target.value; dispatch(fetchProductsData(object)) }} className="grow" placeholder={t('placeholders.search')} />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 16 16"
                      fill="currentColor"
                      className="h-4 w-4 opacity-70">
                      <path
                        fillRule="evenodd"
                        d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
                        clipRule="evenodd" />
                    </svg>
                  </label>
                </div>
                <div className="w-full px-4 my-4 md:w-1/3">
                  <select onChange={(e) => { object.category = e.target.value; dispatch(fetchProductsData(object)) }} className="bg-[#171212] select select-bordered w-full">
                    <option disabled selected>{t('placeholders.filter')}</option>
                    {productsData?.category?.map((opt) =>
                      <option key={opt.id} value={opt.id}>{opt.name}</option>
                    )}
                  </select>
                </div>
              </div>
              {isLoading ? <Loading /> :
                <div className="flex flex-wrap">
                  {productsData?.products?.map((product) =>
                    <div key={product.id} className="lg:w-1/4 md:w-1/3 px-3 my-3 sm:w-1/2 w-full">
                      <Fade>
                        <Link to={`/products/car-details/${product.id}`}>
                          <ProductCard image={product?.mainImage} name={product?.name} collection={product?.categoryName} price={product?.price} />
                        </Link>
                      </Fade>
                    </div>
                  )}
                </div>
              }
            </div>
          </section>
          <FooterBanner />
        </>
      }
    </>
  </>
}

export default Products;