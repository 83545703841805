import React from 'react';
function ServiceCard({image, name, overview}) {
  return <>
    <div className='flex font-Poppins items-center flex-col bg-[#161616] border border-[#383838] rounded-2xl py-14 px-8'>
        <div className='mb-10'> 
          <img src={image} className='w-full' alt="service" />
        </div>
        <div className='prose text-center grow basis-auto'>
            <h4 className='font-bold text-[17px] pb-7 text-white'>{name}</h4>
            <p className='text-[#FAFCFF] text-[15px]'>{overview}</p>
        </div>
    </div>
  </>
}

export default ServiceCard;