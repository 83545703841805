import React from 'react';
import MainTitle from '../MainTitle/MainTitle';
import toyota from '../../assets/images/toyota.png'
import { SwiperSlide, Swiper } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import { useTranslation } from 'react-i18next';
function OurPartnersSection({ data }) {
  const { t } = useTranslation('global');
  return <>
    <section dir='ltr' className='py-16'>
      <MainTitle outline={t('maintitels.customers.outline')} normal={t('maintitels.customers.span')} className={'text-center'} />
      <Swiper
        spaceBetween={20}
        slidesPerView={5}
        breakpoints={{
          586: {
            slidesPerView: 5,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 7,
            spaceBetween: 10,
          },
          1279: {
            slidesPerView: 11,
            spaceBetween: 30,
          },
        }}
        centeredSlides={true}
        autoplay={{
          delay: 1000,
          disableOnInteraction: false,
        }}
        loop={true}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay]}
        className="pointer-events-none"
      >
        {data?.map((partner) =>
        <>
          <SwiperSlide key={partner.id}>
            <img src={partner.logo} alt="partners" className='w-[200px] brightness-50 mx-10 inline-block' />
          </SwiperSlide>
        </>
        )}
      </Swiper>
    </section>
  </>
}

export default OurPartnersSection;