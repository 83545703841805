import React, { useContext, useEffect } from 'react';
import MainTitle from '../MainTitle/MainTitle';
import qs from '../../assets/images/12-removebg-preview 1.png'
import vector1 from '../../assets/images/Ellipse 2.png'
import vector2 from '../../assets/images/Ellipse 1.png'
import { Fade } from 'react-awesome-reveal';
import FooterBanner from '../FooterBanner/FooterBanner';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAboutData } from '../../redux/slices/aboutSlice';
import toast, { Toaster } from 'react-hot-toast';
import Loading from '../Loading/Loading';
import { TranslateContext } from '../../context/TranslateContext';
function AboutUs() {
  const { t } = useTranslation('global');
  let { aboutData, isLoading, error } = useSelector(({ aboutData }) => aboutData);
  const { arLang } = useContext(TranslateContext);
  let dispatch = useDispatch()
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchAboutData())
    if (error) {
      toast.error(error);
    }
  }, [])
  useEffect(() => {
    dispatch(fetchAboutData())
    if (error) {
      toast.error(error);
    }
  }, [arLang])
  return <>
    {isLoading ? <Loading /> :
      <>
        <Toaster />
        <section className='pb-16 pt-40 font-Poppins relative'>
          <img src={vector1} alt="vector" className='absolute right-0 top-0' />
          <img src={vector2} alt="vector" className='absolute left-0 top-0' />
          <div className="container">
            <div className="flex flex-wrap items-center">
              <div className='lg:w-1/2 px-2 my-2 w-full prose'>
                <MainTitle outline={t('maintitels.about_us.outline')} normal={t('maintitels.about_us.span')} />
                <p className='text-[#B6BCC7]'>{aboutData?.AboutUs?.description}</p>

              </div>
              <div className='lg:w-1/2 px-2 my-2 w-full'>
                <div>
                  <Fade direction='down'>
                    <img src={qs} alt="?" className='w-full mx-auto' />
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-16 font-inter'>
          <div className="container overflow-hidden">
            <Fade direction='up'>
              <div className='flex flex-wrap items-center p-20 rounded-r-full bg-[#0d0d0d] border border-[#F90606]'>
                <div className='lg:w-1/2 prose w-full'>
                  <Fade direction='up' delay={100}>
                    <h3 className='md:text-[33px] text-[20px] text-white'>{t('custome_text.why_us')}</h3>
                    <p className='text-[#80AB9E]'>{aboutData?.WhyChooseUs?.description}</p>
                  </Fade>
                </div>
                <div className='lg:w-1/2 w-full'></div>
              </div>
            </Fade>
          </div>
        </section>
        <FooterBanner />
      </>
    }
  </>
}

export default AboutUs;