import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { baseURL } from "../../api/api";

export const fetchServicesDetalisData = createAsyncThunk(
    'servicesDetailsSlice/fetchServicesDetalisData',
    async (id,{rejectWithValue})=>{
        try {
            let {data} = await axios.get(`${baseURL}/service/Details/${id}`,{
              headers:{
                lang: localStorage.getItem('lang')
              }
            });
            return data?.servucesData
        }catch(error){
            return rejectWithValue(error.response?.data || error.message);
        }
    } 
)
let initialState = { serviseDetailsData: {}, isLoading: false, error: null };
let servicesDetailsSlice = createSlice({
    name: 'servicesDetailsSlice',
    initialState,
    extraReducers: (builder) => {
      builder
        .addCase(fetchServicesDetalisData.pending, (state) => {
          state.isLoading = true;
          state.error = null; // Clear any previous error
        })
        .addCase(fetchServicesDetalisData.fulfilled, (state, action) => {
          state.serviseDetailsData = action.payload;
          state.isLoading = false;
        })
        .addCase(fetchServicesDetalisData.rejected, (state, action) => {
          state.isLoading = false;
          state.error = action.payload || 'Failed to fetch home data';
        });
    }
  });

  export const serviceDetailsReducer = servicesDetailsSlice.reducer