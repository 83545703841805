import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { baseURL } from "../../api/api";
import toast from "react-hot-toast";

// Async thunk for fetching home data
export const fetchHomeSlice = createAsyncThunk(
  'homeSlice/fetchHomeSlice',
  async (_, { rejectWithValue }) => {
    try {
      let { data } = await axios.get(`${baseURL}/appdata`,{
        headers:{
          lang: localStorage.getItem('lang')
        }
      });
      return data?.ProductData;
    } catch (error) {
      // Return the error message using rejectWithValue
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Initial state
let initialState = { homeData: {}, isLoading: false, error: null };

// Home slice
let homeSlice = createSlice({
  name: 'homeSlice',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchHomeSlice.pending, (state) => {
        state.isLoading = true;
        state.error = null; // Clear any previous error
      })
      .addCase(fetchHomeSlice.fulfilled, (state, action) => {
        state.homeData = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchHomeSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || 'Failed to fetch home data';
      });
  }
});

export let homeReducer = homeSlice.reducer;
