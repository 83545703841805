import React from 'react';
import { useTranslation } from 'react-i18next';

function ProductCard({ image, collection, name, price}) {
  const {t} = useTranslation('global')
  return <>
    <div className="card font-Poppins flex flex-col bg-[#221f1d] rounded-2xl p-4">
      <div>
        <img src={image} alt="car" className='w-full h-[250px] rounded-2xl' />
      </div>
      <div className='grow basis-auto'>
        <div>
          <p className='text-[#B6BCC7] my-2'>{collection}</p>
          <h4 className='text-white text-[18px] font-semibold'>{name} </h4>
        </div>
        <div className='flex items-center mt-4 justify-between '>
          <div>
            <p className='text-[#B6BCC7] my-1'>{t('custome_text.current_price')}</p>
            <h5 className='text-[#E67E23] font-semibold'>${price}</h5>
          </div>
        </div>
      </div>
    </div>
  </>
}

export default ProductCard;