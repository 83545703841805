import logo from './logo.svg';
import './App.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Layout from './Components/Layout/Layout';
import Home from './Components/Home/Home';
import Products from './Components/Products/Products';
import AboutUs from './Components/AboutUs/AboutUs';
import Services from './Components/Services/Services';
import ServiceDetails from './Components/ServiceDetails/ServiceDetails';
import CarDetails from './Components/CarDetails/CarDetails';
import ContactUs from './Components/ContactUs/ContactUs';
import BuyCar from './Components/BuyCar/BuyCar';
import TranslateProvider from './context/TranslateContext';
import MenuContextProvider from './context/SideMenuContext';
import { Provider } from 'react-redux';
import { stroe } from './redux/store';
const routes = createBrowserRouter([
  {
    path: '', element: <Layout />, children: [
      { index: true, element: <Home /> },
      { path: 'products', element: <Products /> },
      { path: 'about-us', element: <AboutUs /> },
      { path: 'services', element: <Services /> },
      { path: 'services/details/:id', element: <ServiceDetails /> },
      { path: 'products/car-details/:id', element: <CarDetails /> },
      { path: 'contact-us', element: <ContactUs /> },
      { path: 'buy-car/:id/:name', element: <BuyCar /> },
      { path: '*', element: <Home /> },
    ]
  }
])
function App() {
  return <>
    <TranslateProvider>
      <Provider store={stroe}>
        <MenuContextProvider>
          <RouterProvider router={routes}>
          </RouterProvider>
        </MenuContextProvider>
      </Provider>
    </TranslateProvider>
  </>
}

export default App;
