import React from 'react';
import MainTitle from '../MainTitle/MainTitle';
import { Link } from 'react-router-dom';
import pic1 from '../../assets/images/Rectangle 513.png'
import { Fade } from 'react-awesome-reveal';
import pic2 from '../../assets/images/Rectangle 512.png'
import pic3 from '../../assets/images/Rectangle 5.png'
import vector1 from '../../assets/images/Ellipse 2.png'
import vector2 from '../../assets/images/Ellipse 1.png'
import { useTranslation } from 'react-i18next';


function AboutUsSection({data}) {
  const {t} = useTranslation('global');
  return <>
    <section className='py-16 relative'>
      <img src={vector1} className='absolute top-1/2 right-0 -translate-y-1/2 z-[-1]' alt="vector"/>
      <img src={vector2} className='absolute top-1/2 left-0 -translate-y-1/2 z-[-1]' alt="vector" />
      <div className="container">
        <div className="flex flex-wrap items-center">
          <div className='w-full px-2 my-2 lg:w-1/2'>
            <div className='prose'>
              <MainTitle outline={t('maintitels.about_us.outline')} normal={t('maintitels.about_us.span')} />
              <Fade>
                <p className='font-Poppins  text-[#B6BCC7]'>
                  {data?.description}
                </p>
                <Link to={'/about-us'}>
                  <button className='bg-transparent px-5 py-2 border border-white text-[#BDCADB] hover:bg-gradient-to-r hover:from-[#F90606] hover:to-[#CE5D0C] hover:text-white hover:border-transparent duration-[0.3s] rounded-lg'>
                    {t('navlinks.learn_more')}
                  </button>
                </Link>
              </Fade>
            </div>
          </div>
          <div className='w-full px-2 my-2 lg:w-1/2'>
            <div className='flex flex-wrap items-center'>
              <div className='w-2/3 px-5'>
                <div>
                  <Fade delay={400}>
                    <img src={data?.backgroundImages[0]} alt="pic" className='w-full rounded-xl' />
                  </Fade>
                </div>
              </div>
              <div className='w-1/3 px-5'>
                <div>
                  <Fade direction='up'>
                    <img src={data?.backgroundImages[1]} className='rounded-xl' alt="img" />
                  </Fade>
                </div>
                <div>
                  <Fade direction='up' delay={200}>
                    <img src={data?.backgroundImages[2]} className='rounded-xl mt-10' alt="img" />
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
}

export default AboutUsSection;