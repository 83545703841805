import React, { useEffect } from 'react';
import mars from '../../assets/images/mars-img.png'
import { Fade } from 'react-awesome-reveal';
import { FaPhone, FaRocket } from 'react-icons/fa';
import gold1 from '../../assets/images/Ellipsegold 1.png'
import gold2 from '../../assets/images/Ellipsegold 3.png'
import gold3 from '../../assets/images/Ellipsegold 2.png'
import mc from '../../assets/images/1d.png'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchContactUsData } from '../../redux/slices/contactUsSlice';
import toast, { Toaster } from 'react-hot-toast';
import { useFormik } from 'formik';
import { postContactUsFormData } from '../../redux/slices/contactUsFormSlice';
import { MdOutlineMail } from "react-icons/md";
import * as Yup from 'yup'
import Loading from '../Loading/Loading';
function ContactUs() {
  const { t } = useTranslation('global');
  let { contactUsData, isLoading, error } = useSelector(({ contactData }) => contactData);
  let { message, errorS } = useSelector(({ postContact }) => postContact);
  let dispatch = useDispatch()

  const validationSchema = Yup.object({
    Fname: Yup.string().required(t('errors.required')),
    Lname: Yup.string().required(t('errors.required')),
    email: Yup.string().email(t('errors.email')),
    phone: Yup.string().matches(/^\d{11}$/, 'Phone number must be 11 digits').required(t('errors.required')),
    message: Yup.string().required(t('errors.required')),
  });
  let formik = useFormik({
    initialValues: {
      Fname: '',
      Lname: '',
      email: '',
      phone: '',
      message: '',
    }, onSubmit: (values) => {
      dispatch(postContactUsFormData(values));
    }, validationSchema
  })


  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchContactUsData())
    if (error) {
      toast.error(error);
    }
  }, [])
  useEffect(() => {
    dispatch(fetchContactUsData())
    if (error) {
      toast.error(error);
    }
  }, [localStorage.getItem('lang')])
  useEffect(() => {
    if (message.message) {
      toast.success(message?.message)
    }
    console.log(message);
  }, [message])
  useEffect(() => {
    if (errorS) {
      toast.error(errorS?.message)
    }
    console.log(errorS);
  }, [errorS])
  return <>
    {isLoading ? <Loading /> :
      <>
        <Toaster />
        <section className='pt-32 font-Poppins pb-16 overflow-hidden'>
          <div className="container">
            <div className="flex flex-wrap items-center">
              <div className='w-full md:w-1/2'>
                <div className='prose'>
                  <Fade direction='up' delay={400}>
                    <h2 className='font-semibold bg-gradient-to-r from-[#F90606] to-[#CE5D0C] bg-clip-text text-transparent text-[50px] sm:text-[60px] md:text-[70px] lg:text-[80px]'>{t('navlinks.contact_us')}</h2>
                    <p className='text-[#BDCADB] text-[18px] md:text-[20px]'>{contactUsData?.herosection?.description}</p>
                    <a href="#contact">
                      <button className='border-0 py-2 px-7 rounded-xl group outline-0 bg-gradient-to-r from-[#F90606] to-[#CE5D0C] text-white'>
                        <span className='font-Poppins font-semibold text-[18px]'>{t('navlinks.contact_us')}</span>
                      </button>
                    </a>
                  </Fade>
                </div>
              </div>
              <div className='w-full hidden md:block md:w-1/2'>
                <div>
                  <Fade direction='right'>
                    <img src={mars} alt="Mars" className='w-full' />
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-16 font-Poppins'>
          <div className="container">
            <div className='prose lg:w-2/3'>
              <Fade direction='up'>
                <h3 className='text-white text-[30px] md:text-[44px]'>{t('custome_text.discover')}</h3>
                {/* <p className='text-[#999999]'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ultricies tempor purus Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ultricies tempor purus Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
              </Fade>
            </div>
            <div className='flex flex-wrap overflow-hidden'>
              {contactUsData?.address?.map((addr) =>
                <div className='lg:w-1/2 full px-2 my-2 w-full'>
                  <Fade direction='up' duration={1300}>
                    <div className='border border-[#969696] h-[350px] py-3 px-9 rounded-lg'>
                      <Fade direction='up' duration={1300} >
                        <div className='flex flex-col justify-between h-full'>
                          <div className='prose'>
                            <p className='text-[15px] font-medium text-white'>{addr.address}</p>
                            <h4 className='text-[21px] font-bold text-white'>{addr.name}</h4>
                            <p className='text-[14px] text-[#999999]'>{addr.description}</p>
                          </div>
                          <a href={addr.location} target='_blank'>
                            <button className='border-0 w-full py-2 px-4 my-6 rounded-xl group outline-0 bg-gradient-to-r from-[#F90606] to-[#CE5D0C] text-white'>
                              <span className='font-Poppins font-semibold text-[18px]'>{t("custome_text.direction")}</span>
                            </button>
                          </a>
                        </div>
                        <div className='flex items-center flex-wrap'>
                          <div className='w-full px-1 my-1 md:w-1/2'>
                            <div className='border border-[#262626] justify-center space-x-1 rounded-full py-1 bg-[#1A1A1A] px-2 text-[14px] text-white flex items-center'>
                              <FaPhone />
                              <span>{addr.phone}</span>
                            </div>
                          </div>
                          <div className='w-full px-1 my-1 md:w-1/2'>
                            <div className='border border-[#262626] justify-center space-x-1 rounded-full py-1 bg-[#1A1A1A] px-2 text-[14px] text-white flex items-center'>
                              <MdOutlineMail className='text-3xl' />
                              <span>{addr.email}</span>
                            </div>
                          </div>
                        </div>
                      </Fade>
                    </div>
                  </Fade>
                </div>
              )}
            </div>
          </div>
        </section>
        <section className='mt-16 font-inter py-10 relative bg-[#0F0D0D]'>
          <img src={gold1} alt="vector" className='absolute top-0 right-0' />
          <img src={gold2} alt="vector" className='absolute top-0 left-0' />
          <img src={gold3} alt="vector" className='absolute bottom-0 left-0' />
          <div className="container text-center">
            <h2 className='font-extrabold text-[48px] sm:text-[58px] md:text-[68px] text-center inline-block lg:text-[78px] bg-gradient-to-r from-[#fff] to-[#CE5D0C] bg-clip-text text-transparent'>{t("custome_text.get_in_touch")}</h2>
            <p className='text-white font-medium text-[21px]'>{t("custome_text.c_p")}</p>
            <div className="flex bg-[#1c1c1d] mt-10 md:p-6 rounded-2xl flex-wrap">
              <div className='w-full px-4 my-4 lg:w-1/2'>
                <div>
                  {/* <Fade direction='down' delay={200}>
                <h3 className='font-semibold text-white text-base sm:text-[25px] md:text-[32px]'>Car Name EX CESS</h3>
                <p className='text-white mt-3'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ultricies tempor purus.</p>
              </Fade> */}
                  <form onSubmit={formik.handleSubmit} id='contact' className='mt-10'>
                    <Fade delay={400}>
                      <div className='flex flex-wrap items-center'>
                        <div className='w-full px-2 my-3 lg:w-1/2'>
                          <div>
                            <input type="text" name='Fname' onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder={t('placeholders.first_name')} className="input input-bordered outline-none bg-[#171212] w-full" />
                            {formik.touched.Fname && formik.errors.Fname ? <p className='text-left text-red-700'>{formik.errors.Fname}</p> : null}
                          </div>
                        </div>
                        <div className='w-full px-2 my-3 lg:w-1/2'>
                          <div>
                            <input type="text" name='Lname' onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder={t('placeholders.last_name')} className="input input-bordered outline-none bg-[#171212] w-full" />
                            {formik.touched.Lname && formik.errors.Lname ? <p className='text-left text-red-700'>{formik.errors.Lname}</p> : null}
                          </div>
                        </div>
                        <div className='w-full px-2 my-3'>
                          <div>
                            <input type="email" name='email' onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder={t('placeholders.email')} className="input input-bordered outline-none bg-[#171212] w-full" />
                            {formik.touched.email && formik.errors.email ? <p className='text-left text-red-700'>{formik.errors.email}</p> : null}
                          </div>
                        </div>
                        <div className='w-full px-2 my-3'>
                          <div>
                            <input type="text" name='phone' onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder={t('placeholders.phonenumber')} className="input input-bordered outline-none bg-[#171212] w-full" />
                            {formik.touched.phone && formik.errors.phone ? <p className='text-left text-red-700'>{formik.errors.phone}</p> : null}
                          </div>
                        </div>
                        <div className='w-full px-2 my-3'>
                          <textarea name='message' onChange={formik.handleChange} onBlur={formik.handleBlur} className='w-full px-3 py-3 border rounded-xl input-bordered outline-none bg-[#171212]' rows={10} placeholder={t('placeholders.message')}></textarea>
                          {formik.touched.message && formik.errors.message ? <p className='text-left text-red-700'>{formik.errors.message}</p> : null}
                        </div>
                        <div className='w-full px-2 mt-7'>
                          <button type='submit' className='border-0 px-1 mt-7 w-full rounded-lg group py-2 outline-0 bg-gradient-to-r from-[#F90606] to-[#CE5D0C] text-white'>
                            <div className='flex items-center justify-center'>
                              <span className='font-Poppins font-semibold text-[18px]'>{t('placeholders.submit')} </span>
                              <span className='text-white p-2'><FaRocket /></span>
                            </div>
                          </button>
                        </div>
                      </div>
                    </Fade>
                  </form>
                </div>
              </div>
              <div className='w-full px-4 my-4 lg:w-1/2'>
                <div>
                  <Fade>
                    <img src={mc} alt="car" className='w-full' />
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    }
  </>
}

export default ContactUs;