import { configureStore } from "@reduxjs/toolkit";
import { homeReducer } from "./slices/homeSlice";
import { footerReducer } from "./slices/footerSlice";
import { serviceReducer } from "./slices/servicesSlice";
import { productReducer } from "./slices/productSlice";
import { aboutReducer } from "./slices/aboutSlice";
import { serviceDetailsReducer } from "./slices/servicesDetailsSlice";
import { productDetailsReducer } from "./slices/productDetailsSlice";
import { contactUsReducer } from "./slices/contactUsSlice";
import { contactUsFormReducer } from "./slices/contactUsFormSlice";
import { buyCarReducer } from "./slices/buyCarSlice";

export const stroe = configureStore({
    reducer:{
        homeData:homeReducer,
        footerData:footerReducer,
        servicesData:serviceReducer,
        productsData:productReducer,
        aboutData:aboutReducer,
        servicesDetails:serviceDetailsReducer,
        productDetails:productDetailsReducer,
        contactData:contactUsReducer,
        postContact:contactUsFormReducer,
        buyCar:buyCarReducer,
    },
})