import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { baseURL } from "../../api/api";

export const fetchContactUsData = createAsyncThunk(
    'contactUsSlice/fetchContactUsData',
    async (_,{rejectWithValue})=>{
        try {
            let {data} = await axios.get(`${baseURL}/contactus`,{
              headers:{
                lang: localStorage.getItem('lang')
              }
            });
            return data?.data
        }catch(error){
            return rejectWithValue(error.response?.data || error.message);
        }
    } 
)
let initialState = { contactUsData: {}, isLoading: false, error: null };
let contactUsSlice = createSlice({
    name: 'contactUsSlice',
    initialState,
    extraReducers: (builder) => {
      builder
        .addCase(fetchContactUsData.pending, (state) => {
          state.isLoading = true;
          state.error = null; // Clear any previous error
        })
        .addCase(fetchContactUsData.fulfilled, (state, action) => {
          state.contactUsData = action.payload;
          state.isLoading = false;
        })
        .addCase(fetchContactUsData.rejected, (state, action) => {
          state.isLoading = false;
          state.error = action.payload || 'Failed to fetch home data';
        });
    }
  });

  export const contactUsReducer = contactUsSlice.reducer