import React, { useContext, useEffect } from 'react';
import logo from '../../assets/images/Frame 10001.png'
import { Link, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TranslateContext } from '../../context/TranslateContext';
import SideBar from '../SideBar/SideBar';
import { HiBars3BottomLeft } from "react-icons/hi2";
import { menuContext } from '../../context/SideMenuContext';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFooterData } from '../../redux/slices/footerSlice';
function Navbar() {
  let { footerData, error } = useSelector(({ footerData }) => footerData);
  let dispatch = useDispatch()
  const { t } = useTranslation("global");
  const { handelChangeLang, arLang } = useContext(TranslateContext);
  const {setIsMenuOpen} = useContext(menuContext);
  const links = [
    { link: t("navlinks.home"), path: '/' },
    { link: t("navlinks.about_us"), path: '/about-us' },
    { link: t("navlinks.services"), path: '/services' },
    { link: t("navlinks.products"), path: '/products' },
  ]
  useEffect(() => {
    dispatch(fetchFooterData())
  }, [])
  return <>
    <SideBar />
    <header className='fixed w-full z-[9999]'>
      <nav className='py-3 absolute w-full font-Poppins bg-transparent backdrop-blur-md'>
        <div className="container">
          <div className="flex items-center justify-between">
            <div className="logo">
              <img src={footerData?.footer?.logo} className='w-[200px]' alt="AL-Baqari Motors" />
            </div>
            <ul className='items-center hidden lg:flex'>
              {links.map((link, i) =>
                <li key={i} className='mx-7'>
                  <NavLink className="text-[#BDCADB] font-semibold hover:bg-gradient-to-r hover:from-[#F90606] hover:to-[#CE5D0C] hover:bg-clip-text hover:text-transparent duration-[0.3s]" to={link.path}>{link.link}</NavLink>
                </li>
              )}
              <li className='mx-7'>
                {arLang ? <span onClick={() => handelChangeLang("en")} className='text-[#BDCADB] cursor-pointer font-semibold hover:bg-gradient-to-r hover:from-[#F90606] hover:to-[#CE5D0C] hover:bg-clip-text hover:text-transparent duration-[0.3s]'>English</span> :
                  <span onClick={() => handelChangeLang("ar")} className='text-[#BDCADB] cursor-pointer font-semibold hover:bg-gradient-to-r hover:from-[#F90606] hover:to-[#CE5D0C] hover:bg-clip-text hover:text-transparent duration-[0.3s]'>العربية</span>
                }
              </li>
            </ul>
            <Link className='lg:block hidden' to={'/contact-us'}>
              <button className='bg-transparent px-5 py-2 border border-white text-[#BDCADB] hover:bg-gradient-to-r hover:from-[#F90606] hover:to-[#CE5D0C] hover:text-white hover:border-transparent duration-[0.3s] rounded-lg'>
                {t("navlinks.contact_us")}
              </button>
            </Link>
            <HiBars3BottomLeft onClick={()=> setIsMenuOpen(true)} className='text-white text-[45px] lg:hidden mx-5 cursor-pointer'/>
          </div>
        </div>
      </nav>
    </header>
  </>
}

export default Navbar;